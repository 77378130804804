<template>
  <CChartLine :datasets="defaultDatasets" labels="jalans" />
</template>

<script>
import { CChartLine } from "@coreui/vue-chartjs";

export default {
  name: "CChartLineExample",
  components: { CChartLine },
  data: function () {
    return {
      jalans: [
        "Jln Veteran",
        "Jln Diponegoro",
        "Jln Trisula",
        "Jln Malindo",
        "Jln Hamid Matali",
        "Jln Mantoman",
        "Jln Durian",
      ],
      loaded: false,
      downloads: [],
      labels: [],
      showError: false,
    };
  },
  computed: {
    defaultDatasets() {
      return [
        {
          label: "Realisasi",
          backgroundColor: "rgb(228,102,81,0.9)",
          data: [30, 39, 40, 50, 30, 60, 35],
        },
        {
          label: "Rencana",
          backgroundColor: "rgb(0,216,255,0.9)",
          data: [39, 60, 40, 35, 40, 20, 45],
        },
      ];
    },
  },
  methods: {
    requestData() {
      // if (this.package === null || this.package === '' || this.package === 'undefined') {
      //   this.showError = true
      //   return
      // }
      this.resetState();
      axios
        .get(
          `https://api.npmjs.org/downloads/range/${this.period}/${this.package}`
        )
        .then((response) => {
          console.log(response.data);
          this.downloads = response.data.downloads.map(
            (download) => download.downloads
          );
          this.labels = response.data.downloads.map((download) => download.day);
          // this.packageName = response.data.package
          this.setURL();
          this.loaded = true;
        })
        .catch((err) => {
          this.errorMessage = err.response.data.error;
          this.showError = true;
        });
    },
    setURL() {
      history.pushState(
        { info: `npm-stats ${this.package}` },
        this.package,
        `/#/${this.package}`
      );
    },
  },
};
</script>
