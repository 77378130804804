<template>
  <CChartBar
    :datasets="defaultDatasets"
    :options="defaultOptions"
    labels="months"
  />
</template>

<script>
import { CChartBar } from '@coreui/vue-chartjs'

export default {
  name: 'CChartBarExample',
  components: { CChartBar },
  computed: {
    defaultDatasets () {
      return [
        {
          label: 'Rencana',
          backgroundColor: '#396F8B',
          data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 11]
        },
        {
          label: 'Realisasi',
          backgroundColor: '#5CA83F',
          data: [10, 10, 12, 39, 20, 30, 29, 70, 30, 20, 12, 11]
        }
      ]
    },
    defaultOptions () {
      return {
        scales: {
            xAxes: [{
                ticks: {
                    autoSkip: false,
                    maxRotation: 90,
                    minRotation: 90
                }
            }]
        }
      }
    },    
    // computedOptions () {
    //   return deepObjectsMerge(this.defaultOptions, this.options || {})
    // }
  }
}
</script>
