<template>
  <CChartPie
    :datasets="defaultDatasets"
    :labels="['Deviasi < -10%', 'Deviasi -1% s/d -10%', 'Deviasi > 0']"
    :options = options
  />
</template>

<script>
import { CChartPie } from '@coreui/vue-chartjs'

export default {
  name: 'CChartPieExample',
  components: { CChartPie },
  props: {
    options: {
      pieceLabel: {
          mode: 'percentage',
          fontColor: '#fff',
          precision: 1
      }
    }
  },
  computed: {
    defaultDatasets () {
      return [
        {
          backgroundColor: [
            '#FF6384',
            '#36A2EB',
            '#FFCD56',
          ],
          data: [40, 20, 80]
        }
      ]
    }
  }
}
</script>
